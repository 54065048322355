export const dialogStyles = {
  padding: '0 0 10px 0',
  margin: '16px',
  '@media (max-width: 540px)': {
    margin: '8px'
  }
};

export const paperStyles = {
  margin: '8px',
  width: '100%'
};

export const dealUseCouponContainerStyles = {
  marginLeft: 2,
  marginRight: 2,
  marginBottom: 2,
  marginTop: 0,
  '@media (max-width: 540px)': {
    padding: '4px'
  }
};
